$red: #E21A23;
$beige: #F1ECE0;
$yellow: #FBB217;
$blue: #013a80;
$dark-blue: #012e66;
$dark-red: #c8171e;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;600&display=swap');
a {
    text-decoration: none;
}
* {
    box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
}
h3 {
    margin: 0;
    padding: 0;
}
.App {
    min-height: 100vh;
    min-width: 100vw;
    background-image: url('../../assets/bg.jpg');
}
.background {
    background-color: rgba($color: #000000, $alpha: 0.3);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}
.container {
    background-color: $beige;
    position: absolute;
    border-radius: 34px;
    padding: 30px 60px;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: fit-content;
    max-width: 95%;
    height: fit-content;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.8);
    .title {
        color: $red;
        font-size: 3.125rem;
    }
    .description {
        max-width: 50ch;
        color: $red;
        font-weight: 600;
    }
    input {
        width: 100%;
        border: none;
        font-size: 1.25rem;
        border-radius: 34px;
        &[type=text] {
            padding: 15px;
        }
    }
    button {
        display: block;
        background-color: $blue;
        color: white;
        font-weight: 700;
        padding: 13px 29px;
        border-radius: 34px;
        box-shadow: none;
        border: none;
        margin-top: 1em;
        width: 100%;
        font-size: 1.25rem;
        transition: 0.2s;
        &:hover {
            cursor: pointer;
            background-color: $dark-blue;
            transition: 0.2s;
        }
    }
    .disclaimer {
        font-weight: 200;
        font-size: 0.75rem;
    }
}
.wheel__container {
    background-color: $beige;
    border-radius: 34px;
    padding: 30px 60px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: fit-content;
    min-width: 50%;
    max-width: 95%;
    height: fit-content;
    text-align: center;
    div {
        margin: auto;
    }
    .title {
        color: $blue;
        font-size: 2rem;
        margin-bottom: 10px;
        text-align: center;
    }
    button {
        display: block;
        background-color: $red;
        color: white;
        font-weight: 700;
        padding: 13px 29px;
        border-radius: 34px;
        box-shadow: none;
        border: none;
        margin-top: 1em;
        width: 100%;
        font-size: 1.25rem;
        transition: 0.2s;
        &:hover {
            cursor: pointer;
            background-color: $dark-red;
            transition: 0.2s;
        }
        &:disabled {
            opacity: 0.5;
        }
    }
    .disclaimer {
        font-weight: 200;
        font-size: 0.75rem;
    }
    .winner {
        font-size: 1.25rem;
    }
}
.animation img  {
    animation: Ticker 10s infinite ease-in-out;
}
@keyframes Ticker {
    5% {
        transform: rotate(5deg);
    }
    15% {
        transform: rotate(-5deg);
    }
    17% {
        transform: rotate(0deg);
    }
    19% {
        transform: rotate(-5deg);
    }
    21% {
        transform: rotate(0deg);
    }
    23% {
        transform: rotate(-5deg);
    }
    25% {
        transform: rotate(0deg);
    }
    27% {
        transform: rotate(-5deg);
    }
    29% {
        transform: rotate(0deg);
    }
    31% {
        transform: rotate(-5deg);
    }
    33% {
        transform: rotate(0deg);
    }
    35% {
        transform: rotate(-5deg);
    }
    37% {
        transform: rotate(0deg);
    }
    39% {
        transform: rotate(-5deg);
    }
    41% {
        transform: rotate(0deg);
    }
    43% {
        transform: rotate(-5deg);
    }
    45% {
        transform: rotate(0deg);
    }
    47% {
        transform: rotate(-5deg);
    }
    49% {
        transform: rotate(0deg);
    }
    51% {
        transform: rotate(-5deg);
    }
    53% {
        transform: rotate(0deg);
    }
    55% {
        transform: rotate(-5deg);
    }
    57% {
        transform: rotate(0deg);
    }
    59% {
        transform: rotate(-5deg);
    }
    61% {
        transform: rotate(0deg);
    }
    63% {
        transform: rotate(-5deg);
    }
    65% {
        transform: rotate(0deg);
    }
    67% {
        transform: rotate(-5deg);
    }
    69% {
        transform: rotate(0deg);
    }
    71% {
        transform: rotate(-5deg);
    }
    73% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-5deg);
    }
    77% {
        transform: rotate(0deg);
    }
    80% {
        transform: rotate(-5deg);
    }
    85% {
        transform: rotate(0deg);
    }
    90% {
        transform: rotate(-5deg);
    }
    95% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-5deg);
    }
}
.error-toast {
    div {
        background: $dark-red;
        color: white;
        font-weight: bold;
    }
}

@media only screen and (max-width: 600px) {
    .App {
        background-size: contain;
    }
    .container {
        .title {
            font-size: 2rem;
        }
    }
    .sc-gsTCUz {
        max-width: 60vw !important;
        max-height: 60vw !important;
        img {
            width: 22%;
            top: 0;
            right: 0;
        }
    }
}